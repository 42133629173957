export const AUTH_START = 'AUTH_START';
export const EMAIL_VALID = 'EMAIL_VALID';
export const EMAIL_INVALID = 'EMAIL_INVALID';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const PASS_START = 'PASS_START'; 
export const PASS_INVALID = 'PASS_INVALID';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_TOASTER = 'TOGGLE_TOASTER';
